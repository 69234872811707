import { Box, Grid, MenuItem, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { useTranslation } from 'react-i18next';
import TableContainer, { TableColumnHeader } from '../Analytics/ProcedureReports/TableContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CommentSideBar } from '../Analytics/ProcedureReports/CommentsSideBar/CommentSideBar';
import './AllNotifications.scss';
import useLoadThreads from '../../hooks/useLoadThreads';
import SearchBar from '../SearchBar/SearchBar';
import { Toast } from './Notification';
import TutorialService from '../../services/trainee/TraineeService';
import { Access } from '../Auth/Access';
import { WorkInstructionPermissions } from '../../shared/permissions';
import { getFormattedTimeFromUnixTime } from '../../shared/utils/formateDate';
import { RevokedCommentIcon } from '../Icons/CdsIcons';
interface Props {
    isTrainer?: boolean;
}

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
    <Link
        {...props}
        component={RouterLink as any}
    />
);

const AllNotifications: React.FC<Props> = (props) => {
    const [searchVal, setSearchVal] = useState<string>('');
    const [showRightPaneComment, setShowRightPaneComment] = useState<boolean>(false);
    const [activeThreadId, setActiveThreadId] = useState<string>('');
    const [isHoverThread, setIsHoverThread] = useState<boolean>(false);
    const theme = useTheme();
    const [activeRow, setActiveRow] = useState(-1);
    const [commentsThreadData, setCommentsThreadData] = React.useState<any>([]);
    const [activeThreadData, setActiveThreadData] = React.useState<any>([]);
    const { listStepCommentThreads, setListStepCommentThreads, loadCommentData, drawerLoading, currPage, setCurrPage, unreadCountData, totalCommentCountData, totalThreadLength } = useLoadThreads();
    const [notificationLoading, setNotificationLoading] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<any>([]);

    const { companyId } = useParams();

    const search = (key: string) => {
        setSearchVal(key);
        setCurrPage(1);
    };

    useEffect(() => {
        const query = new URLSearchParams(searchQuery);
        const procedureId: any = query.get('procedureId');
        loadCommentData(procedureId, searchVal, currPage, 10);
    }, [searchVal]);

    const location = useLocation();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const procedureId: any = params.get('procedureId');
        if (procedureId) {
            loadCommentData(procedureId);
        }
    }, [location.search]);

    useEffect(() => {
        let arr: any = [];
        listStepCommentThreads?.map((threadData) => {
            arr.push({
                procedure: threadData.procedureName,
                version: `V ${threadData.versionName}`,
                stepName: threadData?.stepName,
                workInstruction: threadData?.workInstructionName,
                startedOn: threadData?.lastComment?.createdOn,
                startedBy: `${threadData?.lastComment?.userFirstName} ${threadData?.lastComment?.userLastName}`,
                comment: threadData?.lastComment?.comment,
                stepNo: threadData?.stepNo,
                unreadCount: threadData?.unreadCount,
                isProcedureAccess: threadData?.isProcedureAccess,
            });
        });
        setCommentsThreadData(arr);
    }, [listStepCommentThreads, location.search]);

    const { search: searchQuery } = useLocation();

    const columnsComment = useMemo(
        () =>
            [
                {
                    Header: <TableColumnHeader label={'WI_LIST.PROCEDURE'} />,
                    accessor: 'procedure',
                    Cell: ({ value, row }) => (
                        <Tooltip title={value}>
                            <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                                {!row.original.isProcedureAccess ? (
                                    <Box>
                                        <RevokedCommentIcon />
                                    </Box>
                                ) : null}

                                <Typography
                                    className="one-line-ellipsis"
                                    sx={{
                                        marginLeft: '8px',
                                        fontSize: '14px',
                                        marginTop: '4px',
                                        fontWeight: row.original.unreadCount ? 600 : 400,
                                        opacity: !row.original.isProcedureAccess ? '40%' : null,
                                    }}
                                >
                                    {value}
                                </Typography>
                            </Grid>
                        </Tooltip>
                    ),
                },
                {
                    Header: <TableColumnHeader label={'COMMON.VERSION'} />,
                    accessor: 'version',
                    disableSortBy: true,
                    Cell: ({ value, row }) => (
                        <Tooltip title={value}>
                            <Typography
                                className="one-line-ellipsis"
                                sx={{
                                    marginLeft: '8px',
                                    fontSize: '14px',
                                    fontWeight: row.original.unreadCount ? 600 : 400,
                                    opacity: !row.original.isProcedureAccess ? '40%' : null,
                                }}
                            >
                                {value}
                            </Typography>
                        </Tooltip>
                    ),
                },
                {
                    Header: <TableColumnHeader label={'COMMON.STEP_NAME'} />,
                    accessor: 'stepName',
                    disableSortBy: true,
                    Cell: ({ value, row }) => (
                        <Tooltip title={value}>
                            <Typography
                                className="one-line-ellipsis"
                                sx={{
                                    marginLeft: '8px',
                                    fontSize: '14px',
                                    fontWeight: row.original.unreadCount ? 600 : 400,
                                    opacity: !row.original.isProcedureAccess ? '40%' : null,
                                }}
                            >
                                {value}
                            </Typography>
                        </Tooltip>
                    ),
                },
                {
                    Header: <TableColumnHeader label={'COMMON.WORK_INSTRUCTION_PROCEDURE_LIBRARY'} />,
                    accessor: 'workInstruction',
                    Cell: ({ value, row }) => (
                        <Tooltip title={value}>
                            <Typography
                                className="one-line-ellipsis"
                                sx={{
                                    marginLeft: '8px',
                                    fontSize: '14px',
                                    fontWeight: row.original.unreadCount ? 600 : 400,
                                    opacity: !row.original.isProcedureAccess ? '40%' : null,
                                }}
                            >
                                {value}
                            </Typography>
                        </Tooltip>
                    ),
                },
                {
                    Header: <TableColumnHeader label={'COMMON.DATE_AND_TIME'} />,
                    accessor: 'startedOn',
                    Cell: ({ value, row }) => (
                        <Tooltip title={getFormattedTimeFromUnixTime(value)}>
                            <span
                                className="one-line-ellipsis"
                                style={{
                                    fontSize: '14px',
                                    fontWeight: row.original.unreadCount ? 600 : 400,
                                    opacity: !row.original.isProcedureAccess ? '40%' : '100%',
                                }}
                            >
                                {getFormattedTimeFromUnixTime(value)}
                            </span>
                        </Tooltip>
                    ),
                },
                {
                    Header: <TableColumnHeader label={'COMMON.COMMENTED_BY'} />,
                    accessor: 'startedBy',
                    disableSortBy: true,
                    Cell: ({ value, row }) => (
                        <Tooltip title={value}>
                            <span
                                className="one-line-ellipsis"
                                style={{
                                    fontSize: '14px',
                                    fontWeight: row.original.unreadCount ? 600 : 400,
                                    opacity: !row.original.isProcedureAccess ? '40%' : '100%',
                                }}
                            >
                                {value}
                            </span>
                        </Tooltip>
                    ),
                },
                {
                    Header: <TableColumnHeader label={'ANALYTICS_SECTION.COMMENT'} />,
                    accessor: 'comment',
                    disableSortBy: true,
                    Cell: ({ value, row }) => (
                        <Tooltip title={value}>
                            <Typography
                                className="one-line-ellipsis"
                                style={{
                                    textOverflow: 'ellipsis',
                                    fontSize: '14px',
                                    fontWeight: row.original.unreadCount ? 600 : 400,
                                    opacity: !row.original.isProcedureAccess ? '40%' : '100%',
                                }}
                            >
                                {value}{' '}
                                {row.original.unreadCount > 1 ? (
                                    <span style={{ color: theme.palette.primary.main }}>
                                        +{row.original.unreadCount - 1} {row.original.unreadCount === 2 ? t('ANALYTICS_SECTION.UNREAD_COMMENT') : t('ANALYTICS_SECTION.UNREAD_COMMENTS')}
                                    </span>
                                ) : null}
                            </Typography>
                        </Tooltip>
                    ),
                },
            ].filter(Boolean),
        [listStepCommentThreads],
    );
    const onRowClick = (row) => {
        if (!row.original.isProcedureAccess) {
            return;
        }
        setActiveRow(row.id);
        setActiveThreadId(listStepCommentThreads[row.id]._id);
        setActiveThreadData(listStepCommentThreads[row.id]);
        setShowRightPaneComment(true);
    };

    function shadeColor(color, percent) {
        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = (R * (100 - percent) + percent * 255) / 100;
        G = (G * (100 - percent) + percent * 255) / 100;
        B = (B * (100 - percent) + percent * 255) / 100;

        R = R < 255 ? R : 255;
        G = G < 255 ? G : 255;
        B = B < 255 ? B : 255;
        R = Math.round(R);
        G = Math.round(G);
        B = Math.round(B);

        var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
        var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
        var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

        return '#' + RR + GG + BB;
    }
    const themePrimaryExtraLight = shadeColor(theme.palette.primary.main, 87);

    const { t } = useTranslation();

    const notificationList = () => {
        setNotificationLoading(true);
        TutorialService.notificationList()
            .then((e) => {
                setNotifications(e.data.data);
                setNotificationLoading(false);
            })
            .catch(({ response }) => {
                Toast.error(t('OPERATION_FAILED', { ns: 'error' }), t(response?.data?.messageCode, { ns: 'error' }) || response?.data?.errorMessage);
                setNotificationLoading(false);
            });
    };
    useEffect(() => {
        notificationList();
    }, []);

    const getNotificationByType = (notification, isTrainer, companyId) => {
        switch (notification.action) {
            case 'revisit':
                return (
                    <LinkRouter to="/trainer/RevisitRequests/">
                        <MenuItem
                            aria-controls="menu-user-manage-md"
                            aria-haspopup="true"
                        >
                            <Box
                                component="span"
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '10px 12px', color: 'black', width: '100%' }}
                            >
                                <Typography sx={{ fontSize: '14px' }}>
                                    <span>{notification.count}</span> <span style={{ fontWeight: '400' }}> {notification.count > 1 ? t('NAVBAR.PERIODIC_REVIEWS') : t('NAVBAR.PERIODIC_REVIEW')}</span>
                                </Typography>
                            </Box>
                        </MenuItem>
                    </LinkRouter>
                );

            case 'recertification':
                return (
                    <Access permission={WorkInstructionPermissions.READ}>
                        <LinkRouter to={`/recertificationDueRequest/${companyId}`}>
                            <MenuItem
                                aria-controls="menu-user-manage-md"
                                aria-haspopup="true"
                            >
                                <Box
                                    component="span"
                                    sx={{ alignItems: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '10px 12px', color: 'black', width: '100%' }}
                                >
                                    <Typography sx={{ fontSize: '14px' }}>
                                        <span>{notification.count}</span> <span style={{ fontWeight: '400' }}> {notification.count > 1 ? t('NAVBAR.RECERTIFICATIONS_DUE') : t('NAVBAR.RECERTIFICATION_DUE')}</span>
                                    </Typography>
                                </Box>
                            </MenuItem>
                        </LinkRouter>
                    </Access>
                );
            case 'approval':
                return (
                    <LinkRouter to="/trainer/ApprovalRequests/">
                        <MenuItem
                            aria-controls="menu-user-manage-md"
                            aria-haspopup="true"
                        >
                            <Box
                                component="span"
                                sx={{ alignItems: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', padding: '10px 12px', color: 'black', width: '100%' }}
                            >
                                <Typography sx={{ fontSize: '14px' }}>
                                    <span>{notification.count}</span> <span style={{ fontWeight: '400' }}> {notification.count > 1 ? t('NAVBAR.APPROVAL_REQUESTS') : t('NAVBAR.APPROVAL_REQUEST')}</span>
                                </Typography>
                            </Box>
                        </MenuItem>
                    </LinkRouter>
                );
            default:
                return false;
        }
    };

    return (
        <>
            <CommentSideBar
                showRightPaneComment={showRightPaneComment}
                setShowRightPaneComment={setShowRightPaneComment}
                setActiveRow={setActiveRow}
                activeThreadId={activeThreadId}
                setActiveThreadId={setActiveThreadId}
                activeThreadData={activeThreadData}
                isTrainer={props.isTrainer}
            />
            <div>
                <Paper
                    elevation={0}
                    className="instructions-title-container"
                    sx={{ display: { xs: 'flex', md: 'flex', marginTop: '16px' } }}
                >
                    <Box
                        component="div"
                        className="left"
                    >
                        <Typography
                            sx={{ fontSize: '24px', fontWeight: 500 }}
                            component="div"
                            className="page-title"
                        >
                            {' '}
                            {t('NAVBAR.NOTIFICATIONS')}{' '}
                        </Typography>
                        {unreadCountData > 0 && (
                            <Typography
                                sx={{
                                    bgcolor: 'grey.300',
                                    color: '#000',
                                    padding: '4px 4px',
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Typography sx={{ marginRight: '4px' }}>{unreadCountData}</Typography>
                                <Typography>{t('PROCEDURE.NEW')}</Typography>
                            </Typography>
                        )}
                    </Box>
                </Paper>
                <Paper
                    elevation={0}
                    className="instructions-title-container"
                    sx={{ display: { xs: 'flex', md: 'flex' }, pt: 0 }}
                >
                    <SearchBar onChangeHandler={(e) => search(e?.target?.value)} />
                </Paper>
                <Paper
                    elevation={0}
                    className="instructions-title-container"
                    sx={{ display: 'flex', flexDirection: 'column', alignItem: 'left' }}
                >
                    {notifications?.map((notification, index) =>
                        notification.action !== 'step comment' ? (
                            <Box
                                component={'div'}
                                key={index}
                                sx={{ backgroundColor: notification.action !== 'step comment' ? theme.palette.primary.main + '1c' : null, width: '100%', marginBottom: '8px', borderRadius: '8px' }}
                            >
                                {/* {value === 0 && notification.action === 'step comment' ? getNotificationByType(notification, isTrainer, companyId) : value === 1 && notification.action === 'revisit' ? getNotificationByType(notification, isTrainer, companyId) : value === 2 && notification.action === 'approval' ? getNotificationByType(notification, isTrainer, companyId) : value === 3 && notification.action === 'recertification' ? getNotificationByType(notification, isTrainer, companyId) : null} */}
                                {getNotificationByType(notification, props.isTrainer, companyId)}
                            </Box>
                        ) : null,
                    )}
                </Paper>

                {drawerLoading && currPage === 1 ? (
                    <Box
                        component={'div'}
                        sx={{ height: '500px', position: 'relative', padding: '24px' }}
                    >
                        <Loading />
                    </Box>
                ) : (
                    <Box
                        component={'div'}
                        id="all-notification-container"
                        sx={{
                            padding: '0px 24px 24px 24px',
                            flexGrow: 1,
                            maxHeight: '300px',

                            width: '100%',
                            '> table tbody :not(tr:hover) .show-on-hover': {
                                display: 'none',
                            },
                            '> table tbody tr:hover .show-on-hover': {
                                display: 'unset',
                            },
                            table: {
                                ' th:nth-child(1)': { width: '15%' },
                                ' th:nth-child(2)': { width: '5%' },
                                ' th:nth-child(3)': { width: '10%' },
                                ' th:nth-child(4)': { width: '20%' },
                                ' th:nth-child(5)': { width: '15%' },
                                ' th:nth-child(6)': { width: '10%' },
                                ' th:nth-child(7)': { width: '25%' },
                            },
                        }}
                    >
                        <InfiniteScroll
                            dataLength={totalThreadLength}
                            next={() => {}}
                            onScroll={() => {
                                if (listStepCommentThreads.length < totalThreadLength && !drawerLoading) {
                                    const query = new URLSearchParams(searchQuery);
                                    const procedureId: any = query.get('procedureId');
                                    loadCommentData(procedureId ? procedureId : '', searchVal, currPage, 10);
                                }
                            }}
                            hasMore={listStepCommentThreads.length < totalThreadLength}
                            loader={<p>{totalThreadLength < 1 ? t('COMMON.NO_THREADS_EXIST') : listStepCommentThreads.length < totalThreadLength ? t('COMMON.LOADING') : null}</p>}
                        >
                            <Paper
                                elevation={0}
                                sx={{ display: 'unset', overflow: 'hidden', overflowY: 'auto' }}
                            >
                                <Box component="div">
                                    <TableContainer
                                        key={listStepCommentThreads.length}
                                        columns={columnsComment}
                                        data={commentsThreadData}
                                        onRowClick={onRowClick}
                                        rowProps={(row) => ({
                                            selected: row.id === activeRow,
                                        })}
                                        commentThreadTable={true}
                                        isHoverThread={isHoverThread}
                                    />
                                </Box>
                            </Paper>
                        </InfiniteScroll>
                    </Box>
                )}
            </div>
        </>
    );
};

export default AllNotifications;
