import { Box, Button, Paper, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import TableContainer from '../../../components/Analytics/ProcedureReports/TableContainer';
import CdsBreadCrumb, { CdsBreadCrumbRoute, getBreadCrumbRoute } from '../../../components/BreadCrumbs/CdsBreadCrumb';
import { ProcedureCardLabels, TaskCount } from '../../../components/Cards/Procedures/TrainerProcedureCard/TrainerProcedureCard';
import { NormalProcedureIcon, TemplateProcedureIcon } from '../../../components/Icons/CdsIcons';
import { BasicDialog } from '../../../components/Trainer/Dialog/BasicDialog/BasicDialog';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import useLoadEntities from '../../../hooks/useLoadEntities';
import TutorialService from '../../../services/trainee/TraineeService';
import { PROCEDURE_VISIBILITY_SCOPE } from '../../../shared/enums';
import { getFormattedDateFromUnixTime } from '../../../shared/utils/formateDate';
import { EntityName } from '../../Trainee/TraineeHome/ReuseableComponents';
import TrainerService from '../../../services/trainer/TrainerService';
import { InfoOutlined } from '@mui/icons-material';
import { moveToLibrary } from '../../../services/ModularProcedure/ModularProcedure';
import SearchBar from '../../../components/SearchBar/SearchBar';
import SelectSort from '../../../components/SelectSort/SelectSort';
import FilterButton from '../../../components/FilterButton/FilterButton';
import { fetchTemplateProcedures } from '../../../redux/features/ModularProcedures/ModularProcedures.slice';
import { debounce } from 'lodash';
import Loading from '../../../components/Loading/Loading';
interface WorkInstructionSearchPayload {
  currPage: number;
  pageSize: number;
  search: string;
  sort: string;
  filter: string;
}

const DEFAULT_2D_WI_SEARCH_PAYLOAD: WorkInstructionSearchPayload = {
  currPage: 1,
  pageSize: 30,
  search: '',
  sort: '',
  filter: ',is2d:true',
};

const SelectWorkInstruction = (props) => {
  const dispatch = useAppDispatch();

  const {
    procedureLibrary: { searchPayload },
  } = useAppSelector((state) => state.modularProcedure);

  const { isOpen, onClose } = props;
  // const imageObject = useRef({})
  const { companyId } = useParams();
  const { t } = useTranslation();
  // const { folders, workInstructions } = useAppSelector((state) => state.homePage);
  // const { loadData, totalWorkInstructionsLength, currPage, parentFolderId, loading, setCurrPage } = useLoadEntities();
  const clickTimeout = useRef<any>(null);

  const tableColumn = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: EntityName,
    },
    {
      Header: 'Description',
      accessor: 'description',
      disableSortBy: true,
      Cell: ({ value, row }) => <Typography sx={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}>{value}</Typography>,
    },
    {
      Header: 'Date Added',
      accessor: 'addedOn',
      Cell: ({ value, row }) => <span style={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}>{getFormattedDateFromUnixTime(value)}</span>,
    },
  ];
  const [tableData, setTableData] = useState([]);
  const [breadCrumbRoute, setBreadCrumbRoute] = useState<CdsBreadCrumbRoute[]>([]);
  const [selectedWorkInstruction, setSelectedWorkInstruction] = useState<string>('');
  const [selectedProcedures, setSelectedProcedures] = useState([]);

  const [workInstructions, setWorkInstructions] = useState<any>([]);
  const [totalWorkInstructionsLength, setTotalWorkInstructionsLength] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [workInstructionSearchPayload, setWorkInstructionSearchPayload] = useState<WorkInstructionSearchPayload>(DEFAULT_2D_WI_SEARCH_PAYLOAD);

  useEffect(() => {
    loadData(workInstructionSearchPayload);
  }, [workInstructionSearchPayload]);

  useEffect(() => {
    populateTableData(workInstructions);
  }, [workInstructions]);

  // todo: similar code present in traineehome.tsx -> optimise the code
  const populateTableData = (workInstructionsList) => {
    const arr: any = [];
    // folders?.map((folder) => {
    //   arr.push({
    //     name: folder.title,
    //     description: folder.description,
    //     brand: '',
    //     addedOn: folder.modifiedOn ? folder.modifiedOn : folder.createdOn,
    //     id: folder.folderId,
    //     type: 'Folder',
    //     workInstructionImageURL: '',
    //     companyId: companyId,
    //   });
    // });
    workInstructionsList?.map((workInstruction) => {
      arr.push({
        name: workInstruction.workInstructionName,
        description: workInstruction.workInstructionDescription,
        brand: workInstruction.brand,
        addedOn: workInstruction.createdOn,
        id: workInstruction.workInstructionId,
        type: 'WorkInstruction',
        workInstructionImageURL: '', //todo: handle this
        companyId: companyId,
        workInstructionId: workInstruction.workInstructionId,
        is2d: workInstruction.is2d,
      });
    });

    setTableData(arr);
  };

  const loadData = (payload: WorkInstructionSearchPayload) => {
    if (!companyId) return;
    if (payload.currPage === 1) {
      setLoading(true);
    }
    TutorialService.getWorkInstructionsByCompanyId(companyId, payload.currPage, payload.pageSize, payload.search, payload.sort, payload.filter)
      .then((response) => {
        const responsePayload = response.data.data;
        if (payload.currPage > 1) {
          setWorkInstructions([...workInstructions, ...responsePayload.workInstructions]);
        } else {
          setWorkInstructions(responsePayload.workInstructions);
        }
        setTotalWorkInstructionsLength(responsePayload.total);
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const nextHandler = () => {
    setCurrPage(workInstructionSearchPayload.currPage + 1);
  };

  const setCurrPage = (pageNum: number) => {
    setWorkInstructionSearchPayload({ ...workInstructionSearchPayload, currPage: pageNum });
  };

  const handleTableRowClick = (row) => {
    const cardId = row.original.id;
    const type = row.original.type;

    if (clickTimeout.current) {
      setCurrPage(1);
      clearTimeout(clickTimeout.current ?? undefined);
      clickTimeout.current = null;
      const cardIdLocation = cardId ?? '';
      getBreadCrumbRoute(cardIdLocation, type).then((arr) => {
        setBreadCrumbRoute(arr);
      });
      switch (row.original.type) {
        case 'WorkInstruction':
          setSelectedWorkInstruction(cardId);
          break;
        // case 'Folder':
        //   loadData(cardId);
        //   break;
      }
    } else {
      clickTimeout.current = setTimeout(() => {
        clickTimeout.current = null;
      }, 500);
    }
  };

  const handleClose = () => {
    onClose();
    setWorkInstructionSearchPayload(DEFAULT_2D_WI_SEARCH_PAYLOAD);
    setSelectedWorkInstruction('');
    setSelectedProcedures([]);
    setBreadCrumbRoute([]);
  };

  const handleBreadCrumbClick = (route) => {
    setSelectedProcedures([]);
    setSelectedWorkInstruction('');
  };

  const handleAddClick = () => {
    // add code to migrate the procedure to library
    let promiseArray = selectedProcedures.map((each) => moveToLibrary(each));
    Promise.allSettled(promiseArray)
      .then((results) => {
        results.forEach((result) => {
          if (result.status !== 'fulfilled') {
            console.log('Failed to move');
          }
        });
      })
      .then(() => {
        dispatch(fetchTemplateProcedures(searchPayload));

        handleClose();
      });
  };
  return (
    <BasicDialog onClose={handleClose} onOpen={isOpen} title={t('PROCEDURE_LIBRARY.SELECT_PROCEDURE_DIALOG.TITLE')} id="select-entity" fullWidth={true} maxWidth={'lg'} actions={<DialogueActionContainer handleClose={handleClose} handleAddClick={handleAddClick} selectedProcedures={selectedProcedures} />}>
      <Paper elevation={0}>
        <CdsBreadCrumb routes={breadCrumbRoute} folderBreadCrumb={true} setBreadCrumbRoute={setBreadCrumbRoute} setCurrPage={setCurrPage} btnClickHandler={handleBreadCrumbClick} />
      </Paper>
      {selectedWorkInstruction ? (
        <ProcedureList selectedWorkInstruction={selectedWorkInstruction} selectedProcedures={selectedProcedures} setSelectedProcedures={setSelectedProcedures} />
      ) : (
        <>
          <WorkInstructionAndFolderSearchToolbar workInstructionSearchPayload={workInstructionSearchPayload} setWorkInstructionSearchPayload={setWorkInstructionSearchPayload} />
          {loading ? (
            <Box component={'div'} sx={{ height: '300px', position: 'relative' }}>
              <Loading />
            </Box>
          ) : (
            <InfiniteScroll dataLength={workInstructions.length} next={nextHandler} hasMore={workInstructions.length < totalWorkInstructionsLength} loader={<p>{t('COMMON.LOADING')}</p>} scrollableTarget="dialogue-content-select-entity">
              <Paper elevation={0}>
                <Box component="div">
                  {tableData.length ? (
                    <TableContainer
                      columns={tableColumn}
                      data={tableData}
                      onRowClick={handleTableRowClick}
                      rowProps={(row) => ({
                        disabled: row.original.type === 'WorkInstruction' && !row.original.is2d,
                      })}
                      isWorkInstructionFolderTable={true}
                    />
                  ) : (
                    <Typography>{t('PROCEDURE_LIBRARY.SELECT_PROCEDURE_DIALOG.NO_WI_TO_SHOW')}</Typography>
                  )}
                </Box>
              </Paper>
            </InfiniteScroll>
          )}
        </>
      )}
    </BasicDialog>
  );
};

const procedureListcolumns = [
  {
    Header: 'Name',
    accessor: 'procedureName',
    Cell: ({ value, row }) => {
      const procedureType = row.original.visibilityScope || PROCEDURE_VISIBILITY_SCOPE.NORMAL;
      return (
        <Typography sx={{ marginLeft: '8px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography>
            {procedureType === PROCEDURE_VISIBILITY_SCOPE.NORMAL ? <NormalProcedureIcon /> : null}
            {procedureType === PROCEDURE_VISIBILITY_SCOPE.REFERENCE ? <TemplateProcedureIcon /> : null}
          </Typography>
          <Typography sx={{ marginLeft: '8px', fontSize: '16px', fontWeight: '600' }}>{value}</Typography>
        </Typography>
      );
    },
  },
  // {
  //   Header: 'Tags',
  //   accessor: 'tags',
  //   disableSortBy: true,
  //   Cell: ({ value, row }) => <Typography sx={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}>{value}</Typography>,
  // },
  {
    Header: 'Steps',
    accessor: 'tasks_count',
    Cell: ({ value, row }) => (
      <Typography component={'div'} sx={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}>
        <TaskCount tasks_count={value} />
      </Typography>
    ),
  },
  {
    Header: 'Status',
    accessor: 'procedureId',
    Cell: ({ value, row }) => {
      const procedure = row.original;

      return (
        <Box
          component={'div'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ fontSize: '14px', fontWeight: 400, opacity: '70%', color: '#312D32' }}>
            <ProcedureCardLabels procedureDetails={procedure} />
          </span>
        </Box>
      );
    },
  },
];

const ProcedureList = (props) => {
  const { selectedWorkInstruction, selectedProcedures, setSelectedProcedures } = props;
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedWorkInstruction) {
      setIsLoading(true);
      TutorialService.getProcedures(selectedWorkInstruction)
        .then((response) => {
          let procedures = response.data.data;
          procedures = procedures.filter((procedure) => (procedure.procedureType ? procedure.procedureType === PROCEDURE_VISIBILITY_SCOPE.NORMAL : true));
          setTableData(procedures as []);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedWorkInstruction]);

  const handleRowClick = (row) => {
    const procedureClicked = row.original.procedureId;

    if (selectedProcedures.includes(procedureClicked)) {
      setSelectedProcedures(selectedProcedures.filter((each) => each !== procedureClicked));
    } else {
      setSelectedProcedures([...selectedProcedures, row.original.procedureId]);
    }
  };

  return (
    <Box component={'div'} sx={{ width: '100%' }}>
      {isLoading ? (
        <p>{t('COMMON.LOADING')}</p>
      ) : tableData.length ? (
        <TableContainer
          columns={procedureListcolumns}
          data={tableData}
          onRowClick={handleRowClick}
          rowProps={(row) => ({
            selected: selectedProcedures.includes(row.original.procedureId),
            disabled: row.original.visibilityScope === PROCEDURE_VISIBILITY_SCOPE.REFERENCE || !row.original.versionId,
          })}
        />
      ) : (
        <Typography>No Procedures to show</Typography>
      )}
    </Box>
  );
};

export default SelectWorkInstruction;

const DialogueActionContainer = (props) => {
  const { t } = useTranslation();
  const { handleClose, handleAddClick, selectedProcedures } = props;
  return (
    <>
      {/* <Box component={'div'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px', marginRight: 'auto', height: '60px' }}>
        <InfoOutlined />
        <span>{t('PROCEDURE_LIBRARY.SELECT_PROCEDURE_DIALOG.3D_WI_NOT_SUPPORTED')}</span>
      </Box> */}
      <Button variant="outlined" onClick={handleClose}>
        {t('BUTTON.CANCEL')}
      </Button>
      <Button disabled={!selectedProcedures.length} variant="contained" onClick={handleAddClick} autoFocus>
        {t('BUTTON.ADD')} {selectedProcedures.length ? ` (${selectedProcedures.length})` : null}
      </Button>
    </>
  );
};

const WorkInstructionAndFolderSearchToolbar = (props) => {
  const { workInstructionSearchPayload, setWorkInstructionSearchPayload } = props;

  const handleSearch = debounce((e) => {
    setWorkInstructionSearchPayload({ ...workInstructionSearchPayload, currPage: 1, search: e?.target?.value });
  }, 500);

  return (
    <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
      <SearchBar onChangeHandler={handleSearch} />
    </Box>
  );
};
