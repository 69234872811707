import { Skeleton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

const LazyImage = (props) => {
    const { src, alt, className, style, width } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [view, setView] = useState('');
    const placeholderRef = useRef(null);
    useEffect(() => {
        setIsLoading(true);
        setView('');
        // Initiating Intersection Observer
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && placeholderRef.current) {
                setView(src);
                observer.unobserve(placeholderRef.current);
            }
        });
        // Observe the placeholder element
        if (placeholderRef && placeholderRef.current) {
            observer.observe(placeholderRef.current);
        }
        return () => observer.disconnect();
    }, [src]);

    return (
        <div
            ref={placeholderRef}
            style={{ position: 'relative', width, height: '100%' }}
        >
            {isLoading && (
                <Skeleton
                    height={'100%'}
                    width={width}
                    // ref={placeholderRef}
                />
            )}
            <img
                src={view} // Gets src only when placeholder intersecting
                className={className}
                style={isLoading ? { display: 'none' } : style}
                alt={alt}
                onLoad={() => setIsLoading(false)}
            />
        </div>
    );
};
export default LazyImage;
